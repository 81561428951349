import React, { useEffect, useState } from "react";

import { Box, Typography, useTheme, Button, FormControl } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ModalAddSection from "../form/ModalAddSection";
import ModalEditSection from "../form/ModalEditSection";
import { authAPI } from "../../axios/authAPI";

const AddSectionManage = () => {
  const [sectionData, setSectionData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    const fetchSectionName = async () => {
      try {
        const fetchdata = await authAPI.get(
          `/api/sections?userid=${userDetails?.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        const responseData = await fetchdata?.data;
        setSectionData(responseData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSectionName();
  }, [flag]);

  const [openModal, setOpenModal] = useState(false);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const handleAddSection = () => {
    setOpenModal(true);
  };

  const handleEditSection = (data) => {
    setOpenSectionModal(true);
    setSelectedData(data);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr",
    },
    {
      field: "sectionName",
      headerName: "Section Name",
      headerAlign: "left",
      align: "center",
      flex: 0.4,
    },
    {
      field: "Edit",
      headerName: "Edit",
      flex: 0.1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center">
            <EditTwoToneIcon
              sx={{ cursor: "pointer", marginRight: "1rem" }}
              onClick={() => handleEditSection(params.row)}
            />
            <DeleteOutlineOutlinedIcon
              sx={{ cursor: "pointer", marginRight: "1rem" }}
            />
          </Box>
        );
      },
    },
  ];
  const theme = useTheme();
  return (
    <Box m="20px">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          Section Manage
        </Typography>
        <Button variant="contained" onClick={() => handleAddSection()}>
          Add Record
        </Button>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
    {subtitle}
  </Typography> */}
      </Box>
      <Box
        m="40px 0 0 0"
        height="58vh"
        // width="80%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[400]}`,
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <Typography
          color={theme.palette.error.main}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          Note: Do not Put Space In Class Name . use - or _ instead of
        </Typography>
        <DataGrid
          rows={sectionData}
          columns={columns}
          //   checkboxSelection
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      {openModal && (
        <Box>
          <ModalAddSection
            openModal={openModal}
            handleClose={() => setOpenModal(false)}
            flag={flag}
            setFlag={setFlag}
          />
        </Box>
      )}

      {openSectionModal && (
        <Box>
          <ModalEditSection
            openSectionModal={openSectionModal}
            handleSectionClose={() => setOpenSectionModal(false)}
            details={selectedData}
            flag={flag}
            setFlag={setFlag}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddSectionManage;
