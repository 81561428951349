import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Blog from "./pages/Blog";
import User from "./pages/User";
import AddStudent from "./components/form/AddStudent";
import System from "./components/Tables/System";
import StudentManage from "./components/Tables/StudentManage";
import SMSTemplate from "./components/Tables/SMSTemplate";
import SetSchoolTime from "./pages/SetSchoolTime";
import SmsReport from "./components/Tables/SmsReport";
import StudentDaywise from "./components/Tables/StudentDaywise";
import StudentAbsentReport from "./components/Tables/StudentAbsentReport";
import AddClassManage from "./components/Tables/AddClassManage";
import AddSectionManage from "./components/Tables/AddSectionManage";
import Login from "./pages/Login";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import Products from "./pages/Products";
import DashboardApp from "./pages/DashboardApp";
import AttendanceReport from "./components/Tables/AttendanceReport";

// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn = localStorage.getItem("jwt");

  return useRoutes([
    {
      path: "/dashboard",
      element:
        isLoggedIn === null ? <Navigate to="/login" /> : <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <AddStudent /> },
        { path: "device_manage", element: <System /> },
        { path: "studentManage", element: <StudentManage /> },
        { path: "sms_template", element: <SMSTemplate /> },
        { path: "school-timing", element: <SetSchoolTime /> },
        { path: "sms_report", element: <SmsReport /> },
        { path: "student_daywise", element: <StudentDaywise /> },
        { path: "student_absent_report", element: <StudentAbsentReport /> },
        { path: "add_cl", element: <AddClassManage /> },
        { path: "add_sc", element: <AddSectionManage /> },
        { path: "monthly_report", element: <AttendanceReport /> },
      ],
    },
    {
      path: "/",

      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
