import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  styled,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const rowsData = [
  [
    {
      name: "mehul",
      day: "Mon",
      date: "03",
      status: "A",
    },
    {
      name: "mehul",
      day: "Tue",
      date: "04",
      status: "A",
    },
    {
      name: "mehul",
      day: "Wed",
      date: "05",
      status: "B",
    },
    {
      name: "mehul",
      day: "Thu",
      date: "06",
      status: "P",
    },
    {
      name: "mehul",
      day: "Fri",
      date: "07",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sat",
      date: "08",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sun",
      date: "09",
      status: "",
    },
    {
      name: "mehul",
      day: "Mon",
      date: "10",
      status: "P",
    },
    {
      name: "mehul",
      day: "Tue",
      date: "11",
      status: "A",
    },
    {
      name: "mehul",
      day: "Wed",
      date: "12",
      status: "B",
    },
    {
      name: "mehul",
      day: "Thu",
      date: "13",
      status: "P",
    },
    {
      name: "mehul",
      day: "Fri",
      date: "14",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sat",
      date: "15",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sun",
      date: "16",
      status: "",
    },
    {
      name: "mehul",
      day: "Mon",
      date: "17",
      status: "P",
    },
    {
      name: "mehul",
      day: "Tue",
      date: "18",
      status: "A",
    },
    {
      name: "mehul",
      day: "Wed",
      date: "19",
      status: "B",
    },
    {
      name: "mehul",
      day: "Thu",
      date: "20",
      status: "P",
    },
    {
      name: "mehul",
      day: "Fri",
      date: "21",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sat",
      date: "22",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sun",
      date: "23",
      status: "",
    },
    {
      name: "mehul",
      day: "Mon",
      date: "24",
      status: "P",
    },
    {
      name: "mehul",
      day: "Tue",
      date: "25",
      status: "A",
    },
    {
      name: "mehul",
      day: "Wed",
      date: "26",
      status: "B",
    },
    {
      name: "mehul",
      day: "Thu",
      date: "27",
      status: "P",
    },
    {
      name: "mehul",
      day: "Fri",
      date: "28",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sat",
      date: "29",
      status: "P",
    },
    {
      name: "mehul",
      day: "Sun",
      date: "30",
      status: "",
    },
  ],
  [
    {
      name: "Nolan",
      day: "Mon",
      date: "03",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Tue",
      date: "04",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Wed",
      date: "05",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Thu",
      date: "06",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Fri",
      date: "07",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Sat",
      date: "08",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Sun",
      date: "09",
      status: "",
    },
    {
      name: "Nolan",
      day: "Mon",
      date: "10",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Tue",
      date: "11",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Wed",
      date: "12",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Thu",
      date: "13",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Fri",
      date: "14",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Sat",
      date: "15",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Sun",
      date: "16",
      status: "",
    },
    {
      name: "Nolan",
      day: "Mon",
      date: "17",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Tue",
      date: "18",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Wed",
      date: "19",
      status: "B",
    },
    {
      name: "Nolan",
      day: "Thu",
      date: "20",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Fri",
      date: "21",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Sat",
      date: "22",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Sun",
      date: "23",
      status: "",
    },
    {
      name: "Nolan",
      day: "Mon",
      date: "24",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Tue",
      date: "25",
      status: "A",
    },
    {
      name: "Nolan",
      day: "Wed",
      date: "26",
      status: "B",
    },
    {
      name: "Nolan",
      day: "Thu",
      date: "27",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Fri",
      date: "28",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Sat",
      date: "29",
      status: "P",
    },
    {
      name: "Nolan",
      day: "Sun",
      date: "30",
      status: "",
    },
  ],
];

export default function AttendanceReport() {
  const [value, setValue] = React.useState(null);
  const theme = useTheme();
  const MainContainerStyle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > div": {
        width: "100%",
        marginTop: 15,
        "&.classList": {
          "& > div": {
            marginTop: 15,
          },
        },
        "& > button": {
          padding: "6px 6px",
          margin: "0px 2px",
        },
        "& > div": {
          width: "100%",
        },
      },
    },
  }));
  return (
    <Box m="20px">
      <Box>
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Student Attendance Report
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
      {subtitle}
    </Typography> */}
      </Box>
      <MainContainerStyle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box className="classList">
          <FormControl
            sx={{ width: "10rem", marginTop: "0.5rem", marginRight: "1rem" }}
          >
            <InputLabel id="demo-simple-select-label">Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="Class"
              name="Class"
              // value={formik.values.Sections}
              // label="Sections"
              // onChange={formik.handleChange}
              // error={formik.touched.Sections && Boolean(formik.errors.Sections)}
              // helperText={formik.touched.Sections && formik.errors.Sections}
            >
              <MenuItem value="Class">Class</MenuItem>

              {/* <MenuItem value={"20"}>Twenty</MenuItem>
              <MenuItem value={"30"}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "10rem", marginTop: "0.5rem" }}>
            <InputLabel id="demo-simple-select-label">Sections</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="Sections"
              name="Sections"
              // value={formik.values.Sections}
              // label="Sections"
              // onChange={formik.handleChange}
              // error={formik.touched.Sections && Boolean(formik.errors.Sections)}
              // helperText={formik.touched.Sections && formik.errors.Sections}
            >
              <MenuItem value="logic">Logic</MenuItem>

              {/* <MenuItem value={"20"}>Twenty</MenuItem>
              <MenuItem value={"30"}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateField"]}>
            <DateField
              label="From"
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>
        <Box
          width="25rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
          >
            Export PNG
          </Button>
          <Button
            variant="contained"
            sx={{ width: "7rem", height: "3rem", marginTop: "0.5rem" }}
          >
            Print
          </Button>
        </Box>
      </MainContainerStyle>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {rowsData[0].map((r) => (
                <TableCell align="center">
                  {r.date} {r.day}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map((row) => (
              <TableRow
                key={row[0].name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {console.log(row)}
                <TableCell component="th" scope="row">
                  {row[0].name}
                </TableCell>
                {row.map((item) => (
                  <TableCell align="center">{item.status}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
