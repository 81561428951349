import React, { useEffect, useState } from "react";

import { Box, Typography, useTheme, Button, styled } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import Modal from "@mui/material/Modal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import { authAPI } from "../../axios/authAPI";
import SMSButton from "../button/SMSButton";

import ModalEditStudent from "../form/ModalEditStudent";
import ModalDeleteStudent from "../form/ModalDeleteStudent";

const StudentManage = () => {
  const theme = useTheme();
  const [studentData, setStudentData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [deleteID, setDeleteID] = useState();
  const [flag, setFlag] = useState(true);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const MainContainerStyle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& > div": {
        width: "49%",
        paddingLeft: "0 !important",
        marginTop: 10,
        "& button": {
          width: "100%",
        },
      },
    },
  }));
  useEffect(() => {
    const getStudentData = async () => {
      // const testData = await authAPI.get(`/api/add-students`);
      // const responseData = await testData?.data?.data;
      const checkData = await authAPI.get(
        `/api/add-students?userid=${userDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const newResponseData = await checkData?.data;

      const check = newResponseData.map((item, idx) => ({
        ...item,
        Sr_ID: idx + 1,
        classDetails: `${item?.Class}-${item?.Sections}`,
        editField: "editField",
      }));
      // console.log(check);

      setStudentData(check);
    };

    getStudentData();
  }, [flag]);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDelete = (data) => {
    setDeleteID(data);
    setOpenDeleteModal(true);
  };
  const handleSelectedData = (data) => {
    setSelectedData(data);
    setOpenModal(true);
  };

  const columns = [
    {
      field: "Sr_ID",
      headerName: "Sr",
      headerAlign: "left",
      align: "left",
      // flex: 0.1,
    },
    {
      field: "IcardNo",
      headerName: "Icard No.",
      headerAlign: "left",
      align: "left",
      width: 150,
      // flex: 0.2,
    },
    {
      field: "Name",
      headerName: "Full Name",
      // flex: 0.2,
      width: 350,
      cellClassName: "name-column-cell",
    },
    {
      field: "classDetails",
      headerName: "Class",
      // flex: 0.2,
    },
    {
      field: "Contact_No",
      headerName: "Contact Number",
      // flex: 0.2,
      width: 130,
    },
    {
      field: "RFID_No",
      headerName: "RFID",
      // flex: 0.2,
      width: 130,
    },
    {
      field: "Admission_No",
      headerName: "Admission NO",
      // flex: 0.2,
      width: 250,
    },
    {
      field: "Status",
      headerName: "Status",
      // flex: 0.2,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Box>
            <Typography
              sx={{
                color: "white",
                padding: "0 4px",
                borderRadius: "4px",
                backgroundColor: `${theme.palette.success.dark}`,
              }}
            >
              Enable
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "editField",
      headerName: "editField",
      width: 200,
      headerAlign: "left",
      align: "left",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center">
            <EditTwoToneIcon
              sx={{ cursor: "pointer", marginRight: "1rem" }}
              onClick={() => handleSelectedData(params.row)}
            />
            <RemoveCircleTwoToneIcon
              sx={{ cursor: "pointer" }}
              onClick={() => handleDelete(params.row)}
            />
            <Button
              variant="contained"
              color="success"
              sx={{ marginLeft: "1rem" }}
            >
              Upload
            </Button>
          </Box>
        );
      },
    },
  ];
  console.log(studentData);
  return (
    <Box m="20px">
      <Box mb="30px">
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          Student Manage
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
      </Box>
      {/* 
      <MainContainerStyle display="flex" flexDirection="row">
        <SMSButton
          subText="Make Present (with SMS)"
          backgroundColorButton={theme.palette.grey[300]}
          colorText="black"
          hoverBG={theme.palette.grey[400]}
          hoverText="black"
        />
        <SMSButton
          subText="Make Present (No SMS)"
          backgroundColorButton={theme.palette.grey[300]}
          colorText="black"
          hoverBG={theme.palette.grey[400]}
          hoverText="black"
        />

        <SMSButton
          subText="Disable"
          backgroundColorButton={theme.palette.error.main}
          colorText="white"
          hoverBG={theme.palette.error.dark}
          hoverText="white"
        />

        <SMSButton
          subText="Enable"
          backgroundColorButton={theme.palette.success.main}
          colorText="white"
          hoverBG={theme.palette.success.dark}
          hoverText="white"
        />
      </MainContainerStyle> */}
      <Box
        m="40px 0 0 0"
        height="58vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[600]}`,
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          disableColumnFilter
          rows={studentData}
          columns={columns}
          // checkboxSelection
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}

          showCellVerticalBorder="true"
          // pageSizeOptions={[1, 2, 3]}

        />
      </Box>
      {openModal && (
        <Box>
          <ModalEditStudent
            openModal={openModal}
            handleClose={() => setOpenModal(false)}
            selectedData={selectedData}
            setFlag={setFlag}
            flag={flag}
          />
        </Box>
      )}

      {openDeleteModal && (
        <Box>
          <ModalDeleteStudent
            openDeleteModal={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            deleteID={deleteID}
            setFlag={setFlag}
            flag={flag}
          />
        </Box>
      )}
    </Box>
  );
};

export default StudentManage;
