// ----------------------------------------------------------------------

export default function DataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-virtualScroller": {
            "@media (max-width:991px)": {
              overflowX: "scroll !important",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            "@media (max-width: 991px)": {
              overflow: "inherit",
              textOverflow: "unset",
              whiteSpace: "noWrap",
            },
          },
          "& .MuiDataGrid-columnHeader": {
            "@media (max-width: 991px)": {
              minWidth: "250px !important",
              flex: 1,
              width: "auto !important",
              "&:nth-child(1)": {
                minWidth: "100px !important",
              },
            },
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            "@media (max-width: 991px)": {
              minWidth: "250px !important",
              width: "auto !important",
              flex: 1,
              maxHeight: "inherit !important",
              minHeight: "inherit !important",
              "&:nth-child(1)": {
                minWidth: "100px !important",
              },
            },
          },
        },
      },
    },
  };
}
