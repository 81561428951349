import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Typography,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Alert from "@mui/material/Alert";
import { authAPI } from "../../axios/authAPI";
import CircleLoader from "../../assets/gif/circle_loading.gif";

const DataDisplayModal = ({
  setOpenDataModal,
  openDataModal,
  selectedAddData,
}) => {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [expanded, setExpanded] = useState("panel1");
  const [loader, setLoader] = useState(false);
  const [indiData, setIndiData] = useState({ passed: 0, failed: 0 });
  const [failedCases, setFailedCases] = useState([]);
  const [displayTableData, setDisplayDataTable] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? "70%" : "95%",
    minHeight: "40%",
    maxHeight: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };

  const [err, setErr] = useState("");

  useEffect(() => {
    const handleSubmit = async () => {
      const promiseArray = [];
      setLoader(true);
      for (let i = 0; i < selectedAddData.length; i++) {
        promiseArray.push(
          authAPI
            .post(
              `/api/add-students`,
              { data: selectedAddData[i] },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            )
            .then((val) => {
              return setIndiData((prev) => ({
                ...prev,
                passed: prev.passed + 1,
              }));
            })
            .catch((err) => {
              const error = err?.response?.data?.error?.details?.errors[0];
              setIndiData((prev) => ({
                ...prev,
                failed: prev.failed + 1,
              }));
              setFailedCases((prev) => [
                ...prev,
                { user: selectedAddData[i], error },
              ]);
              setDisplayDataTable((prev) => [
                ...prev,
                {
                  ...selectedAddData[i],
                  id: parseInt(selectedAddData[i]["Sr. No."], 10),
                },
              ]);
            })
        );
      }

      Promise.all(promiseArray)
        .then((values) => {
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleSubmit();
  }, []);
  console.log(failedCases);
  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Academic Year",
      headerName: "Academic Year",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "Admission_No",
      headerName: "Admission_No",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "RFID_No",
      headerName: "RFID_No",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "Name",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "Class",
      headerName: "Class",
      headerAlign: "left",
      align: "left",
      // flex: 0.1,
    },
    {
      field: "Sections",
      headerName: "Sections",
      headerAlign: "left",
      align: "left",
      // flex: 0.1,
    },
    {
      field: "Date",
      headerName: "Date",
      headerAlign: "left",
      align: "left",
      // flex: 0.1,
    },
    {
      field: "IcardNo",
      headerName: "IcardNo",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "Father's Name",
      headerName: "Father's Name",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "Contact_No",
      headerName: "Contact_No",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "email",
      headerName: "email",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
  ];

  const total =
    ((indiData.passed + indiData.failed) / selectedAddData.length) * 100;

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "5rem auto",
        }}
      >
        <Box sx={{ width: "80%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.floor(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: "0.5rem",
    "&:not(:last-child)": {
      //   borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon sx={{ fontSize: "1rem", color: "white" }} />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  return (
    <Box m="20px">
      <Modal
        open={openDataModal}
        // onClose={setOpenDataModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "sticky",
              top: "0px",
              backgroundColor: "white",
              zIndex: "99",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ m: "0 0 5px 0" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Details of Records !!
            </Typography>
            <Typography
              sx={{ m: "0 0 5px 0" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              A list of {selectedAddData.length} data has been uploaded out of
              which
            </Typography>
            <CloseIcon
              sx={{
                position: "absolute",
                top: 15,
                right: 0,
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={setOpenDataModal}
            />
          </Box>

          {loader ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={total} />
            </Box>
          ) : (
            <Box
              m={isNonMobile ? "5rem 2rem 2rem 2rem" : "3rem 0rem 1rem 0rem"}
            >
              <Box m="1rem 0 1rem 0">
                <Typography>Results:</Typography>
              </Box>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{
                    // backgroundColor: "#B4FFB2",
                    backgroundColor: theme.palette.success.main,
                  }}
                >
                  {indiData?.passed === 0 ? (
                    <Typography color="white" fontWeight="bold">
                      No Passed Data
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography color="white" fontWeight="bold">
                        Passed Data
                      </Typography>
                      <Typography
                        color="black"
                        fontWeight="bold"
                        sx={{
                          padding: "1px 4px",
                          backgroundColor: "white",
                          fontSize: "12px",
                          marginLeft: "5px",
                          borderRadius: "3px",
                        }}
                      >
                        {` ${indiData?.passed}`}
                      </Typography>
                    </Box>
                  )}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "#EFFFE8",
                  }}
                >
                  {indiData?.passed === 0 ? (
                    <Typography>Please upload the data again !!!</Typography>
                  ) : (
                    <Typography>
                      Data has been added to the list of Student Manage Table ->
                      <Link to={"/Dashboard/studentManage"}>
                        Student Manage
                      </Link>
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  sx={{
                    // backgroundColor: "#FEC7C7",
                    backgroundColor: theme.palette.error.main,
                  }}
                >
                  {failedCases === 0 ? (
                    <Typography color="white" fontWeight="bold">
                      No Failed Data
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography color="white" fontWeight="bold">
                        Failed Data
                      </Typography>
                      <Typography
                        color="black"
                        fontWeight="bold"
                        sx={{
                          padding: "1px 4px",
                          backgroundColor: "white",
                          fontSize: "12px",
                          marginLeft: "5px",
                          borderRadius: "3px",
                        }}
                      >
                        {` ${failedCases.length}`}
                      </Typography>
                    </Box>
                  )}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "#fff8f5",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography margin="1rem 0">
                      Here are the list of data which didn't got passed
                    </Typography>
                    <Typography
                      margin="1rem 0"
                      sx={{
                        color: theme.palette.info.dark,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowTable(!showTable)}
                    >
                      {showTable ? "Close Table" : "View Table"}
                    </Typography>
                  </Box>
                  {showTable && (
                    <Box
                      m="1.5rem 0 1.5rem 0"
                      height="58vh"
                      sx={{
                        "& .MuiDataGrid-root": {
                          border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                          border: 1,
                          borderColor: `${theme.palette.grey[500]}`,
                        },
                        "& .name-column--cell": {
                          // color: "red",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: `${theme.palette.grey[300]}`,
                          borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: `${theme.palette.grey[200]}`,
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          backgroundColor: `${theme.palette.grey[300]}`,
                        },
                        "& .MuiCheckbox-root": {
                          color: `${theme.palette.grey[700]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                          color: `${theme.palette.grey[700]} !important`,
                        },
                      }}
                    >
                      <DataGrid
                        disableColumnFilter
                        rows={displayTableData.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )}
                        columns={columns}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        slots={{
                          toolbar: GridToolbar,
                        }}
                      />
                    </Box>
                  )}
                  <Box display="flex" flexDirection="column" gap="2rem">
                    {failedCases.map((item, idx) => {
                      return (
                        <Box
                          width="100%"
                          border={`1px solid ${theme.palette.error.main}`}
                          backgroundColor={theme.palette.error.contrastText}
                          key={idx}
                          p="1rem"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            sx={{ backgroundColor: theme.palette.error.main }}
                            color="white"
                          >
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              {`${item?.error.name}: `}
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.error?.path[0]} => `}{" "}
                              <b>{`${item.user[item?.error?.path[0]]} `}</b>
                              {`${item?.error?.message}`}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              Sr No:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user["Sr. No."]} `}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              Student Name:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user.Name} `}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              Class:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user.Class}`}-{`${item?.user.Sections}`}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              IcardNo:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user.IcardNo} `}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              RFID NO:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user.RFID_No} `}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="row">
                            <Typography sx={{ m: "5px" }} fontWeight="bold">
                              Admission NO:
                            </Typography>
                            <Typography sx={{ m: "5px" }}>
                              {`${item?.user.Admission_No} `}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default DataDisplayModal;
