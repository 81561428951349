import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";

import SchoolIcon from "@mui/icons-material/School";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import Modal from "@mui/material/Modal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SMSTemplateModal from "./SMSTemplateModal";
import { authAPI } from "../../axios/authAPI";

const SMSTemplate = () => {
  const [smsTemplateData, setSmsTemplateData] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    const fetchData = async () => {
      const apiData = await authAPI.get(
        `/api/sms-templates?userid=${userDetails?.id}`
      );
      const responseData = await apiData?.data?.data;

      const check = responseData.map((item, idx) => ({
        ...item.attributes,
        id: item?.id,
        Sr_Id: idx + 1,
      }));
      setSmsTemplateData(check);
    };

    fetchData();
  }, []);
  const theme = useTheme();
  const columns = [
    {
      field: "Sr_Id",
      headerName: "Sr",
      flex: 0.1,
    },
    {
      field: "Template_type",
      headerName: "Template Type",
      headerAlign: "left",
      align: "left",
      flex: 0.2,
    },
    {
      field: "SMS_Format",
      headerName: "SMS Format",
      flex: 0.4,
      cellClassName: "name-column-cell",
    },
    {
      field: "Category",
      headerName: "Remark",
      flex: 0.2,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 0.1,
      renderCell: () => {
        return (
          <Box>
            <Typography
              sx={{
                color: "white",
                padding: "0 4px",
                borderRadius: "4px",
                backgroundColor: `${theme.palette.success.dark}`,
              }}
              //   fontSize="0.9rem"
            >
              Active
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.1,
      renderCell: () => {
        return (
          <Box>
            <EditTwoToneIcon />
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.1,
      renderCell: () => {
        return (
          <Box>
            <RemoveCircleTwoToneIcon />
          </Box>
        );
      },
    },

    // {
    //   field: "liveFunc",
    //   headerName: "Live",
    //   flex: 0.2,
    //   renderCell: () => {
    //     return (
    //       <Box onClick={handleOpen}>
    //         <Typography
    //           sx={{
    //             color: `${theme.palette.primary.light}`,
    //             cursor: "pointer",
    //           }}
    //           variant="h6"
    //         >
    //           Live
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box m="20px">
      <Box mb="30px">
        <Typography
          variant="h4"
          color={theme.palette.grey[600]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SchoolIcon
            sx={{ padding: "0 2px", fontSize: "2rem", marginRight: "5px" }}
          />
          SMS Template
        </Typography>
        {/* <Typography variant="h5" color={colors.greenAccent[400]}>
      {subtitle}
    </Typography> */}
      </Box>
      <Box>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Add Template
        </Button>
        <SMSTemplateModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      </Box>

      <Box
        m="40px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: `${theme.palette.grey[400]}`,
          },
          "& .name-column--cell": {
            // color: "red",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${theme.palette.grey[300]}`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: `${theme.palette.grey[200]}`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: `${theme.palette.grey[300]}`,
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.grey[700]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.grey[700]} !important`,
          },
        }}
      >
        <DataGrid
          sx={{ overflowx: "scroll" }}
          rows={smsTemplateData}
          columns={columns}
          //   checkboxSelection
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          disableColumnFilter
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SMSTemplate;
