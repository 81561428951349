import axios from "axios";

const { host } = window.location;

let baseURL = "";

if (host.includes("localhost")) {
  baseURL = "http://localhost:1337";
} else if (host.includes("ams.hl1.in")) {
  baseURL = "https://bk.ams.hl1.in/";
}

export const authAPI = axios.create({
  baseURL,
});

export const Url = baseURL;
