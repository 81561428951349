import React, { useState } from "react";

import { Box, Typography, useTheme, styled } from "@mui/material";
import {ClickAwayListener} from '@mui/base';
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import Groups3Icon from "@mui/icons-material/Groups3";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

const MainContainerStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  "@media (max-width: 1199px)": {
    position: "fixed",
    zIndex: 9,
  },
  "& .pro-sidebar-inner": {
    backgroundColor: `${theme.palette.grey[900]} !important`,
    position: "fixed !important",
    width: "270px",
    top: 72,

    height: "calc(100% - 72px) !important",
    [theme.breakpoints.down("lg")]: {
      top: "64px",
      height: "calc(100% - 64px) !important",
    },
  },
  "& .pro-icon-wrapper": {
    backgroundColor: "transparent !important",
  },
  "& .pro-inner-item": {
    padding: "10px 35px 10px 20px !important",
    borderRadius: "0",
    [theme.breakpoints.down("md")]: {
      "& .pro-item-content": {
        whiteSpace: "normal !important",
      },
    },
  },
  "& .pro-inner-item:hover": {
    backgroundColor: `${theme.palette.grey[800]} !important`,
    color: `${theme.palette.grey[100]} !important`,
  },
  "& .pro-menu-item": {
    position: "relative",
  },
  "& .pro-menu-item.active": {
    color: `${theme.palette.grey[300]} !important`,
    backgroundColor: `${theme.palette.grey[800]} !important`,
  },
  "& .pro-sidebar .pro-menu": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.collapseDrawer": {
    width: 72,
    "& .pro-arrow-wrapper": {
      right: "10px !important",
    },
    "@media (max-width: 1199px)": {
      width: 0,
    },
    "& .pro-sidebar-inner": {
      width: 72,
      "& .pro-sidebar-layout": {
        overflowX: "inherit !important",
        overflowY: "inherit !important",
      },
      "& .react-slidedown.pro-inner-list-item": {
        backgroundColor: `${theme.palette.grey[900]} !important`,
        position: "absolute",
        left: "100%",
        top: 0,
        paddingLeft: "0 !important",
      },
      "@media (max-width: 1199px)": {
        width: 0,
        overflow: "hidden",
      },
    },
  },
}));

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  setMenuIndex,
  isNonMobileScreen,
  handleSide,
}) => {
  const theme = useTheme();
  const selectItem = () => {
    if (!isNonMobileScreen) {
      handleSide();
    } else {
      console.log(false);
    }
    setMenuIndex("");
    setSelected(title);
  };
  return (
    <MenuItem
      active={title === selected}
      style={{ color: theme.palette.grey[300] }}
      onClick={selectItem}
      icon={icon}
      open={title === selected}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SubItem = ({
  subTitle,
  children,
  icon,
  index,
  menuIndex,
  setMenuIndex,
  open,
}) => {
  const theme = useTheme();
  const openSubmenu = () => {
    setMenuIndex(subTitle);
    if (subTitle === menuIndex) {
      setMenuIndex(false);
    }
  };
  return (
    <SubMenu
      title={subTitle}
      style={{ color: theme.palette.grey[300] }}
      icon={icon}
      onClick={openSubmenu}
      open={subTitle === menuIndex}
    >
      {children}
    </SubMenu>
  );
};

const TestSide = ({ open, handleSidebar, isNonMobileScreen, handleClick }) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const [menuIndex, setMenuIndex] = useState("");
  return (
    <MainContainerStyle className={open ? "collapseDrawer" : null}>
    <ClickAwayListener onClickAway={(e) => {
      console.log('this', isNonMobileScreen)
      if(!isNonMobileScreen && !open) {
      e.preventDefault()
      e.stopPropagation()
      handleClick()
      }
    }}>
      
      <ProSidebar collapsed={isCollapsed} style={{ minWidth: "0" }}>
      
        <Menu iconShape="square">
          <Box>
            <Item
              title="Dashboard"
              to="/Dashboard/app"
              selected={selected}
              setSelected={setSelected}
              icon={<HomeIcon />}
              setMenuIndex={setMenuIndex}
              handleSide={() => handleSidebar()}
              isNonMobileScreen={isNonMobileScreen}
            />
            <SubItem
              subTitle="Student"
              icon={<SchoolIcon />}
              open={open}
              menuIndex={menuIndex}
              setMenuIndex={setMenuIndex}
            >
              <Item
                title="Add Student"
                to="/Dashboard/user"
                selected={selected}
                setSelected={setSelected}
                setMenuIndex={setMenuIndex}
                handleSide={() => handleSidebar()}
                isNonMobileScreen={isNonMobileScreen}
              />

              <Item
                title="Student Manage"
                to="/Dashboard/studentManage"
                selected={selected}
                setSelected={setSelected}
                setMenuIndex={setMenuIndex}
                handleSide={() => handleSidebar()}
                isNonMobileScreen={isNonMobileScreen}
              />
            </SubItem>

            <Item
              title="System"
              to="/Dashboard/device_manage"
              selected={selected}
              icon={<StackedLineChartIcon />}
              setSelected={setSelected}
              setMenuIndex={setMenuIndex}
              handleSide={() => handleSidebar()}
              isNonMobileScreen={isNonMobileScreen}
            />
            <SubItem
              subTitle="Attendance Report"
              icon={<StackedLineChartIcon />}
              menuIndex={menuIndex}
              open={open}
              setMenuIndex={setMenuIndex}
            >
              <Item
                title="Student(daywise)"
                to="/Dashboard/student_daywise"
                selected={selected}
                setSelected={setSelected}
                setMenuIndex={setMenuIndex}
                handleSide={() => handleSidebar()}
                isNonMobileScreen={isNonMobileScreen}
              />

              {/* <Item
                title="Attendance Student(monthly)"
                to="/Dashboard/monthly_report"
                selected={selected}
                setSelected={setSelected}
                setMenuIndex={setMenuIndex}
              /> */}
              <Item
                title="Student Absent Report"
                to="/Dashboard/student_absent_report"
                selected={selected}
                setSelected={setSelected}
                setMenuIndex={setMenuIndex}
                handleSide={() => handleSidebar()}
                isNonMobileScreen={isNonMobileScreen}
              />
            </SubItem>
            <Item
              title="Sent SMS Report"
              to="/Dashboard/sms_report"
              selected={selected}
              icon={<LocalPostOfficeIcon />}
              setSelected={setSelected}
              setMenuIndex={setMenuIndex}
              handleSide={() => handleSidebar()}
              isNonMobileScreen={isNonMobileScreen}
            />
          </Box>
        </Menu>

      </ProSidebar>

    </ClickAwayListener>
    </MainContainerStyle>
  );
};

export default TestSide;
